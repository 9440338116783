import Avatar from 'components/avatar'

import css from './key-point.module.scss'

export type KeypointProps = {
  point: string
  header: string
  subheader?: string
}

export const KeyPoint = ({ point, header, subheader, ...props }: KeypointProps) => {
  return (
    <div className={css.container} {...props}>
      <Avatar alt={point} className={css.point} backgroundColor="#202020" />
      <div className={css.header}>{header}</div>
      <div className={css.subheader}>{subheader}</div>
    </div>
  )
}
