import React from 'react'

import ImageComponent from 'components/image'
import Link from 'components/link'

import { useTranslate } from 'hooks'

import css from './index.module.scss'

export type MediaInfo = {
  mediaSrc: string
  mediaName: string
  reportLink: string
  overrideReadLink?: string
}

type MediaCardProps = MediaInfo

const MediaCard = ({ mediaName, reportLink, mediaSrc, overrideReadLink }: MediaCardProps): JSX.Element => {
  const { t } = useTranslate('landing-page')

  return (
    <div className={css.container}>
      <div className={css.imageContainer}>
        <ImageComponent src={mediaSrc} alt={mediaName} height={100} width={170} />
      </div>
      <p className={css.reportHighLight}>{t(`media_columns.${mediaName}.article_highlight`)}</p>
      <Link className={css.reportLink} href={reportLink} target="_blank">
        {overrideReadLink ?? t('media_columns.read_article')}
      </Link>
    </div>
  )
}

export default MediaCard
