import TileCarouselTabs, { TileCarouselTabsProps } from 'components/molecules/tile-carousel-tabs'
import { TileGroup, TileGroupProps } from 'components/molecules/tile-group'
import Tab from 'components/tabs/tab'

import css from './tile-group-tab.module.scss'

type TileGroupTabProps = TileGroupProps & { title?: string }

export type TileGroupTabsProps = {
  tileGroups: TileGroupTabProps[]
  tileCarouselTabs: TileCarouselTabsProps
  title?: string
}

export const TileGroupTabs = ({ title, tileGroups, tileCarouselTabs }: TileGroupTabsProps) => {
  return (
    <div className={css.container}>
      <p className={css.header}>{title}</p>
      {tileGroups.length > 1 ? (
        <TileCarouselTabs {...tileCarouselTabs}>
          {tileGroups.map((tileGroup, i) => (
            <Tab key={`${i}`} title={tileGroup.title} tabKey={`${i}`}>
              <div className={css.tabContent}>
                <TileGroup {...tileGroup}></TileGroup>
              </div>
            </Tab>
          ))}
        </TileCarouselTabs>
      ) : (
        <div className={css.tabContent}>
          <TileGroup {...tileGroups[0]}></TileGroup>
        </div>
      )}
    </div>
  )
}
