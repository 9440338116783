import React from 'react'

import cn from 'classnames'

import { Carousel } from 'components/carousel'

import css from './index.module.scss'
import MediaCard, { MediaInfo } from './media-card'

export type MediaColumnsProps = {
  overrideReadLink?: string
}

const MediaColumns = ({ overrideReadLink }: MediaColumnsProps): JSX.Element => {
  const mediaColumnsInfos: MediaInfo[] = [
    {
      mediaName: 'tech_crunch',
      mediaSrc: '/images/article1.png',
      reportLink: 'https://techcrunch.com/2023/08/28/markato/',
    },
    {
      mediaName: 'retail_in_asia',
      mediaSrc: '/images/article2.png',
      reportLink:
        'https://retailinasia.com/featured/how-hong-kong-start-up-markato-plans-to-revolutionise-b2b-retail-in-asia/',
    },
    {
      mediaName: 'tech_in_asia',
      mediaSrc: '/images/article3.png',
      reportLink: 'https://www.techinasia.com/uber-deliveroo-execs-launch-hk-wholesale-platform',
    },
  ]
  return (
    <div className={css.container}>
      <div className={css.content}>
        {mediaColumnsInfos.map((mediaColumnsInfo) => (
          <MediaCard key={mediaColumnsInfo.mediaName} {...mediaColumnsInfo} overrideReadLink={overrideReadLink} />
        ))}
      </div>
      <div className={css.contentSlider}>
        <Carousel
          className={cn(['swiper-pagination-keypoint', 'mediaColumnSlider'])}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          loop
          navigation={false}
          slides={mediaColumnsInfos.map((mediaColumnsInfo) => (
            <div className={css.sliderContainer} key={mediaColumnsInfo.mediaName}>
              <MediaCard key={mediaColumnsInfo.mediaName} {...mediaColumnsInfo} overrideReadLink={overrideReadLink} />
              <div style={{ height: '30px' }} />
            </div>
          ))}
        />
      </div>
    </div>
  )
}

export default MediaColumns
