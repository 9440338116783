
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import jwtDecode from 'jwt-decode'

import Button from 'components/button'
import { useModal } from 'components/modal/use-modal'
import { Banner } from 'components/molecules/banner'
import { CountryTileCarousel } from 'components/organisms/country-tile-carousel'
import { KeypointWithSlider } from 'components/organisms/keypoint-with-slide'
import { TileGroupTabs } from 'components/organisms/tile-group-tab'
import { useRetailerLoginSSO } from 'features/login/use-login'
import MediaColumns from 'features/media-columns'

import { useDynamoDbUserContext } from 'contexts/DynamoDbUserContext'
import { useTranslate } from 'hooks'

import { AccessToken, getErrorCodes, setAccessToken } from 'services/http-client'
import { fireTrackingEvent } from 'utils/tracking'

import { REDIRECT_TO_COOKIE } from 'constants/constants'
import { ROUTES } from 'constants/routes'

import {
  auBrands,
  bestSellerTiles,
  euBrands,
  featuredTiles,
  newArrivalTiles,
  trendingBrands,
  usBrands,
} from '../constants/lpData'
import scss from '../styles/javascript.export.module.scss'
import css from './landing-page.module.scss'

export default function Home({ openSignUp, openLogin }: { openSignUp: () => void; openLogin: () => void }) {
  const { t, langSubPath } = useTranslate('strapi-home')

  const router = useRouter()
  const { code, error_description, error, openLoginModal, openSignUpModal, resetPassword, redirectTo } = router.query
  const { mutateAsync: login } = useRetailerLoginSSO()
  const { open: openForgotPassword } = useModal()
  const { getUserFromDynamo } = useDynamoDbUserContext()

  // Google OAuth
  useEffect(() => {
    if (code && code.length > 6) {
      const logIn = async () => {
        await login(
          { codeGrant: code as string },
          {
            onSuccess: ({ data: { accessToken, expiresIn } }) => {
              setAccessToken(accessToken, expiresIn)
              const decodedToken: AccessToken = jwtDecode(accessToken)
              if (decodedToken['cognito:groups'].findIndex((group: string) => group === 'Retailer') === -1) {
                fireTrackingEvent('success', {
                  name: `success_signup_creation_retailer`,
                })
                fireTrackingEvent('success', {
                  name: `success_verification_retailer`,
                })
                void router.push(ROUTES.RETAILER_ONBOARDING)
              } else {
                getUserFromDynamo((userInfoJson, cognitoId) => {
                  fireTrackingEvent('success', {
                    name: 'success_login_retailer',
                    detail: {
                      name: `${userInfoJson?.firstName ?? ''} ${userInfoJson?.lastName ?? ''}`,
                      email: userInfoJson?.email,
                      group: 'retailer',
                      cognitoId: cognitoId,
                    },
                  })
                })
                const REDIRECT_URL = sessionStorage.getItem(REDIRECT_TO_COOKIE)
                sessionStorage.removeItem(REDIRECT_TO_COOKIE)
                if (REDIRECT_URL) {
                  void router.push(REDIRECT_URL)
                } else {
                  void router.push(ROUTES.RETAILER)
                }
              }
            },
            onError: (error) => {
              fireTrackingEvent('failure', {
                name: 'failure_login_retailer',
                errorCode: ['SSO_ERR', ...getErrorCodes(error)].join(','),
                nativeEvent: error,
              })
            },
          }
        )
      }

      void logIn()
    }
    if (error) {
      fireTrackingEvent('failure', {
        name: 'failure_login_retailer',
        errorCode: ['SSO_ERR', `SSO_${(error as string).toUpperCase()}`].join(','),
        nativeEvent: { error, error_description },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, error_description, error])

  useEffect(() => {
    if (resetPassword) {
      openForgotPassword()
    }
    if (openLoginModal) {
      if (redirectTo) {
        sessionStorage.setItem(REDIRECT_TO_COOKIE, `${langSubPath}${redirectTo as string}`)
      }
      openLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPassword, openLoginModal])

  useEffect(() => {
    if (openSignUpModal) {
      openSignUp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSignUpModal])

  const applyToSell = () => {
    void router.push(ROUTES.BRAND_REGISTRATION)
  }

  const learnMore = () => {
    location.href = ROUTES.QUICK_START_GUIDE
  }

  return (
    <>
      <Head>
        <title>{t('seo.metaTitle')}</title>
      </Head>
      <main className={css.container}>
        <Banner
          backgroundImage="/images/lp/header-banner.jpg"
          mainText={t('Home.Home_TopBanner_MainText')}
          secondaryText={t('Home.Home_TopBanner_SubText')}
          button={{
            name: t('Home.Home_TopBanner_Button_2'),
            buttonFunction: applyToSell,
            trackName: 'cta_home_top_banner_sell',
            testId: 'cta_home_top_banner_sell',
            buttonColor: 'black',
          }}
          secondButton={{
            name: t('Home.Home_TopBanner_Button_1'),
            buttonFunction: openSignUp,
            trackName: 'cta_home_top_banner_shop',
            testId: 'cta_home_top_banner_shop',
            buttonColor: 'white',
          }}
          thirdButton={{
            name: t('Home.Home_TopBanner_Button_3'),
            buttonFunction: learnMore,
            trackName: 'cta_home_top_banner_learn_more',
            testId: 'cta_home_top_banner_learn_more',
            buttonColor: 'white',
          }}
        />
        <TileGroupTabs
          title={t('Home.Home_Section1_Title')}
          tileCarouselTabs={{ tabsProps: { variant: 'rounded' } }}
          tileGroups={[
            {
              tiles: bestSellerTiles,
              title: t('Home.Home_Section1_tab_3'),
              overridelinkText: t('Home.Common_browse_all'),
            },
            {
              tiles: newArrivalTiles,
              title: t('Home.Home_Section1_tab_2'),
              overridelinkText: t('Home.Common_browse_all'),
            },
            {
              tiles: featuredTiles,
              title: t('Home.Home_Section1_tab_1'),
              overridelinkText: t('Home.Common_browse_all'),
            },
          ]}
        />
        <CountryTileCarousel
          overrideLinkText={t('Home.Common_browse_all')}
          tabs={[
            {
              carouselTiles: usBrands,
              tabKey: t('Home.Common_USA'),
              title: t('Home.Home_Section2_tabs', { country: t('Home.Common_USA') }),
            },
            {
              carouselTiles: euBrands,
              tabKey: t('Home.Common_Europe'),
              title: t('Home.Home_Section2_tabs', { country: t('Home.Common_Europe') }),
            },
            {
              carouselTiles: auBrands,
              tabKey: t('Home.Common_Australia'),
              title: t('Home.Home_Section2_tabs', { country: t('Home.Common_Australia') }),
            },
          ]}
        />
        <CountryTileCarousel
          overrideLinkText={t('Home.Common_browse_all')}
          tabs={[
            {
              carouselTiles: trendingBrands,
              tabKey: t('Home.Home_Section3_title'),
              title: t('Home.Home_Section3_title'),
            },
          ]}
        />
        <div className={css.cta}>
          <p className={css.ctaHeader}>{t('Home.Home_Section4_title')}</p>
          <p className={css.ctaSubheader}>{t('Home.Home_Section4_subtitle')}</p>
          <Button
            className={css.ctaButton}
            label={t('Home.Home_Section4_button')}
            onClick={openSignUp}
            trackName="cta_home_middle_shop"
          />
        </div>
        <KeypointWithSlider
          slides={[
            {
              image: '/images/lp/kp-1.jpg',
              point: '1',
              header: t('Home.Home_Section5_header1'),
              subheader: t('Home.Home_Section5_subheader1'),
            },
            {
              image: '/images/lp/kp-2.jpg',
              point: '2',
              header: t('Home.Home_Section5_header2'),
              subheader: t('Home.Home_Section5_subheader2'),
            },
            {
              image: '/images/lp/kp-3.jpg',
              point: '3',
              header: t('Home.Home_Section5_header3'),
              subheader: t('Home.Home_Section5_subheader3'),
            },
            {
              image: '/images/lp/kp-4.jpg',
              point: '4',
              header: t('Home.Home_Section5_header4'),
              subheader: t('Home.Home_Section5_subheader4'),
            },
          ]}
        />
      </main>
      <div className={css.mediaColumnsContainer}>
        <MediaColumns overrideReadLink={t('Home.Home_Section6_columntext')} />
      </div>
      <div className={css.container}>
        <div className={css.cta}>
          <p className={css.ctaHeader}>{t('Home.Home_Section6_title')}</p>
          <p className={css.ctaSubheader}>{t('Home.Home_Section6_subtitle')}</p>
          <a href={t('Home.Home_Section6_link')} target="_blank" rel="noreferrer">
            <Button
              className={css.ctaButton}
              trackName="cta_home_bottom_consultation"
              testId="cta_home_bottom_consultation"
              label={t('Home.Home_Section6_button')}
            />
          </a>
        </div>
        <Banner
          isReverse
          backgroundColor={scss.primaryBlue2}
          backgroundImage="/images/lp/footer-banner.jpg"
          mainText={t('Home.Home_BottomBanner_MainText')}
          secondaryText={t('Home.Home_BottomBanner_SubText')}
          button={{
            name: t('Home.Home_BottomBanner_Button'),
            buttonFunction: applyToSell,
            trackName: 'cta_home_bottom_banner_sell',
            testId: 'cta_home_bottom_banner_sell',
            buttonColor: 'black',
          }}
        />
      </div>
    </>
  )
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  