import Image from 'next/image'

import cn from 'classnames'

import Button from 'components/button'

import { useTranslate } from 'hooks'

import scss from '../../../styles/javascript.export.module.scss'
import css from './banner.module.scss'

type BannerButtonProps = {
  name: string
  buttonFunction: () => void
  trackName?: string
  testId?: string
  buttonColor: 'black' | 'white'
}

export type BannerProps = {
  backgroundImage: string
  backgroundColor?: string
  mainText: string
  secondaryText?: string
  button?: BannerButtonProps
  secondButton?: BannerButtonProps
  thirdButton?: BannerButtonProps
  isReverse?: boolean
  size?: 'default' | 'small'
}

export const Banner = ({
  backgroundImage,
  backgroundColor = scss.primaryBlue1,
  mainText,
  secondaryText,
  button,
  secondButton,
  thirdButton,
  isReverse = false,
  size,
}: BannerProps) => {
  const { lang } = useTranslate('')
  return (
    <section className={cn([css.container, isReverse && css.reverse, size && css[size]])}>
      <div className={css.content} style={{ backgroundColor: backgroundColor }}>
        <p className={cn([css.header, css[lang.split('-')[0]]])}>{mainText}</p>
        <p className={css.subheader}>{secondaryText}</p>
        <div className={css.CTA}>
          {!!button?.name && (
            <Button
              className={cn([css.button, button.buttonColor === 'white' && css.white])}
              label={button.name}
              onClick={button.buttonFunction}
              trackName={button.trackName}
              testId={button.testId}
            ></Button>
          )}
          {!!secondButton && (
            <Button
              style="ghost"
              className={cn([css.button, secondButton.buttonColor === 'white' && css.white])}
              label={secondButton.name}
              onClick={secondButton.buttonFunction}
              trackName={secondButton.trackName}
              testId={secondButton.testId}
            ></Button>
          )}
          {!!thirdButton && (
            <Button
              label={thirdButton.name}
              style="ghost"
              color="whiteLink"
              trackName={thirdButton.trackName}
              testId={thirdButton.testId}
              onClick={thirdButton.buttonFunction}
            />
          )}
        </div>
      </div>

      <div className={css.imageContainer}>
        <Image
          className={css.bgImage}
          src={`${backgroundImage}`}
          alt=""
          height={1350}
          width={1800}
          objectFit="cover"
          loading="eager"
        />
        <div className={css.imageText}>
          <p className={css.header}>{mainText}</p>
          <p className={css.subheader}>{secondaryText}</p>
          <div className={css.CTA}>
            {!!button?.name && (
              <Button
                className={css.button}
                label={button.name}
                onClick={button.buttonFunction}
                trackName={button.trackName}
                testId={button.testId}
              ></Button>
            )}
            {!!secondButton && (
              <Button
                className={cn([css.button, css.white])}
                label={secondButton.name}
                onClick={secondButton.buttonFunction}
                trackName={secondButton.trackName}
                testId={secondButton.testId}
              ></Button>
            )}
            {!!thirdButton && (
              <Button
                label={thirdButton.name}
                style="ghost"
                color="whiteLink"
                trackName={thirdButton.trackName}
                testId={thirdButton.testId}
                onClick={thirdButton.buttonFunction}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
