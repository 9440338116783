import { Tile, TileProps } from 'components/atoms/tile'
import { Carousel } from 'components/carousel'
import { KeyPoint, KeypointProps } from 'components/molecules/key-point'

import css from './keypoint-with-slider.module.scss'

type KeypointWithSlideProps = KeypointProps & TileProps

export type KeypointWithSliderProps = {
  slides: KeypointWithSlideProps[]
  bigSlideSpacing?: number
  smallSlideSpacing?: number
}

export const KeypointWithSlider = ({
  slides,
  bigSlideSpacing = 50,
  smallSlideSpacing = 40,
}: KeypointWithSliderProps) => {
  return (
    <>
      <div className={css.container}>
        <div className={css.left}>
          {slides
            .filter((_, i) => {
              if (i >= 2) return false
              return true
            })
            .map((slide) => (
              <KeyPoint point={slide.point} header={slide.header} subheader={slide.subheader} key={slide.header} />
            ))}
        </div>
        <div className={css.middle}>
          <div className={css.carouselContainer}>
            <Carousel
              navigation={false}
              loop
              autoplay={{ delay: 3000, disableOnInteraction: true }}
              className="swiper-pagination-keypoint"
              pagination
              spaceBetween={15}
              slides={slides.map((slide, i) => (
                <>
                  <Tile image={slide.image} variant="long" key={i} />
                  <div style={{ height: bigSlideSpacing }} />
                </>
              ))}
            />
          </div>
        </div>
        <div className={css.right} style={{ paddingBottom: bigSlideSpacing }}>
          {slides
            .filter((_, i) => {
              if (i >= 2) return true
              return false
            })
            .map((slide) => (
              <KeyPoint point={slide.point} header={slide.header} subheader={slide.subheader} key={slide.header} />
            ))}
        </div>
      </div>
      <div className={css.smallContainer}>
        <Carousel
          autoplay={{ delay: 3000, disableOnInteraction: true }}
          className="swiper-pagination-keypoint"
          navigation={false}
          loop
          pagination
          spaceBetween={15}
          slides={slides.map((slide) => (
            <div className={css.slide} key={slide.image}>
              <Tile variant="big" isTileGroup image={slide.image} isKeypointTile />
              <KeyPoint point={slide.point} header={slide.header} subheader={slide.subheader} />
              <div style={{ height: smallSlideSpacing }} />
            </div>
          ))}
        />
      </div>
    </>
  )
}
